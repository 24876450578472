<template>
  <Content class="verify" v-if="$locale">
    <Header :options="{ remove: ['user'] }">
      <template v-slot:user>
        <router-link to="/" class="avatar close-btn">
          <figure><iconic name="times" /></figure>
        </router-link>
      </template>
    </Header>
    <div class="verify-content">
      <Sticky class="verify-title">
        <div class="main">{{ $locale["words"]["become_creator"] }}</div>
      </Sticky>
      <div class="verify-main main">
        <VerifyUserVerified v-if="$isCreator" />
        <CreatorBecomeHome v-else-if="!apply" />
        <CreatorBecomeSteps v-else-if="apply" />
      </div>
    </div>
  </Content>
</template>

<script>
export default {
  components: {
    Header: () => import("../home/Header.vue"),
    Sticky: () => import("../widgets/Sticky.vue"),
    Content: () => import("../widgets/Content.vue"),
    CreatorBecomeHome: () => import("./CreatorBecomeHome.vue"),
    CreatorBecomeSteps: () => import("./CreatorBecomeSteps.vue"),
    VerifyUserVerified: () => import("../verify-user/VerifyUserVerified.vue"),
  },
  computed: {
    apply: function() {
      return this.$route.query.apply;
    },
  },
};
</script>

<style lang="scss">
.verify {
  &-content {
    margin: $header_height 0 0 0;
  }
  &-title {
    font-size: 2rem;
    padding: $mpadding;
    background-color: #fff;
    z-index: 2;
    box-shadow: $dshadow;
  }
  &-legend {
    text-align: center;
    padding: $mpadding * 2 $mpadding;
    font-size: 2rem;
  }
  &-features {
    max-width: $tablet_width/1.5;
    margin: 0 auto;
    padding: 0 $mpadding;
  }
}
</style>
